import React from 'react';
import './styles/Footer.css';

function Footer() {

  return (
    <div className='Footer-container'>
      <p>Created by Adrienne and Whitney 2019</p>
    </div>
  )
}

export default Footer;