import React from 'react';
import './styles/Header.css';

function Header() {

  return (
    <div className='Header-container'>
      <h1>Petful</h1>
    </div>
  )
}

export default Header;